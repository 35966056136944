@import 'partials/variables';
@import 'partials/mixins';
@import 'partials/fonts';
@import 'partials/form';
@import 'partials/layout';
@import 'partials/thread';
@import 'partials/chart';
@import 'partials/card';
@import 'partials/contexify';
@import 'partials/placesAutocomplete';

body {
    font-family: $font-family;
}

span.delete-wrapper:hover + .ant-image .ant-image-mask {
    opacity: 1;
}
